var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "12" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-notes" } }),
                      _vm._v(" クーポン登録/編集 ")
                    ],
                    1
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CForm",
                        [
                          _c(
                            "div",
                            { staticClass: "form-group form-row" },
                            [
                              _c("CCol", { attrs: { sm: "3" } }, [
                                _vm._v("クーポンID")
                              ]),
                              !this.isNew
                                ? _c("CCol", { attrs: { sm: "9" } }, [
                                    _vm._v(
                                      _vm._s(this.coupon.jan_code) +
                                        _vm._s(
                                          (
                                            "000000" + this.coupon.couponCode
                                          ).slice(-6)
                                        ) +
                                        " (JANコード＋クーポン番号）"
                                    )
                                  ])
                                : _vm._e(),
                              this.isNew
                                ? _c("CCol", { attrs: { sm: "9" } }, [
                                    _vm._v("自動採番されます")
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("CInput", {
                            attrs: {
                              label: "クーポン名",
                              placeholder: "クーポン名...",
                              horizontal: "",
                              lazy: true,
                              isValid: _vm.checkIfValid("title"),
                              invalidFeedback: "クーポン名を入力してください。"
                            },
                            model: {
                              value: _vm.coupon.title,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "title", $$v)
                              },
                              expression: "coupon.title"
                            }
                          }),
                          _c("CInput", {
                            attrs: {
                              label: "商品規格",
                              placeholder: "商品規格...",
                              horizontal: ""
                            },
                            model: {
                              value: _vm.coupon.subTitle,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "subTitle", $$v)
                              },
                              expression: "coupon.subTitle"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "form-group form-row" },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { tag: "label", sm: "3" }
                                },
                                [_vm._v(" カテゴリ ")]
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "form-inline",
                                  attrs: { sm: "9" }
                                },
                                [
                                  _c("CSelect", {
                                    attrs: {
                                      horizontal: "",
                                      options: _vm.genrePullDown,
                                      placeholder: "Please select",
                                      value: _vm.genre_id
                                    },
                                    on: {
                                      "update:value": [
                                        function($event) {
                                          _vm.genre_id = $event
                                        },
                                        function($event) {
                                          return _vm.onUpdateGenre($event)
                                        }
                                      ]
                                    }
                                  }),
                                  _c("CSelect", {
                                    staticStyle: { "padding-left": "10px" },
                                    attrs: {
                                      horizontal: "",
                                      options: _vm.categoryPullDown,
                                      placeholder: "Please select",
                                      value: _vm.category_id
                                    },
                                    on: {
                                      "update:value": function($event) {
                                        _vm.category_id = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group form-row" },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { tag: "label", sm: "3" }
                                },
                                [_vm._v(" 酒類 ")]
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "form-inline",
                                  attrs: { sm: "9" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coupon.isAlcohol,
                                        expression: "coupon.isAlcohol"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: _vm.coupon.isAlcohol,
                                      checked: Array.isArray(
                                        _vm.coupon.isAlcohol
                                      )
                                        ? _vm._i(_vm.coupon.isAlcohol, null) >
                                          -1
                                        : _vm.coupon.isAlcohol
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.coupon.isAlcohol,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.coupon,
                                                "isAlcohol",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.coupon,
                                                "isAlcohol",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(_vm.coupon, "isAlcohol", $$c)
                                        }
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c("CSelect", {
                            attrs: {
                              label: "メーカー名",
                              horizontal: "",
                              options: _vm.makerPullDown,
                              placeholder: "Please select",
                              value: _vm.coupon.maker_id
                            },
                            on: {
                              "update:value": [
                                function($event) {
                                  return _vm.$set(
                                    _vm.coupon,
                                    "maker_id",
                                    $event
                                  )
                                },
                                function($event) {
                                  return _vm.onUpdateMaker($event)
                                }
                              ]
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "form-group form-row" },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { tag: "label", sm: "3" }
                                },
                                [_vm._v(" 卸発行クーポン ")]
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "form-inline",
                                  attrs: { sm: "9" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coupon.wholesaleFlag,
                                        expression: "coupon.wholesaleFlag"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      disabled: _vm.disabledWholesaleFlag
                                    },
                                    domProps: {
                                      checked: _vm.coupon.wholesaleFlag,
                                      checked: Array.isArray(
                                        _vm.coupon.wholesaleFlag
                                      )
                                        ? _vm._i(
                                            _vm.coupon.wholesaleFlag,
                                            null
                                          ) > -1
                                        : _vm.coupon.wholesaleFlag
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.coupon.wholesaleFlag,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.coupon,
                                                "wholesaleFlag",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.coupon,
                                                "wholesaleFlag",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.coupon,
                                            "wholesaleFlag",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _c("CSelect", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.coupon.wholesaleFlag,
                                        expression: "coupon.wholesaleFlag"
                                      }
                                    ],
                                    attrs: {
                                      horizontal: "",
                                      options: _vm.wholesalePullDown,
                                      placeholder: "Please select",
                                      value: _vm.coupon.wholesale_id
                                    },
                                    on: {
                                      "update:value": [
                                        function($event) {
                                          return _vm.$set(
                                            _vm.coupon,
                                            "wholesale_id",
                                            $event
                                          )
                                        },
                                        function($event) {
                                          return _vm.onUpdateWholesale($event)
                                        }
                                      ]
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group form-row" },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { tag: "label", sm: "3" }
                                },
                                [_vm._v(" 小売発行クーポン ")]
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "form-inline",
                                  attrs: { sm: "9" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coupon.distributionFlag,
                                        expression: "coupon.distributionFlag"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      disabled: !_vm.isAdmin()
                                    },
                                    domProps: {
                                      checked: _vm.coupon.distributionFlag,
                                      checked: Array.isArray(
                                        _vm.coupon.distributionFlag
                                      )
                                        ? _vm._i(
                                            _vm.coupon.distributionFlag,
                                            null
                                          ) > -1
                                        : _vm.coupon.distributionFlag
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.coupon.distributionFlag,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.coupon,
                                                "distributionFlag",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.coupon,
                                                "distributionFlag",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.coupon,
                                            "distributionFlag",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  }),
                                  _c("CSelect", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.coupon.distributionFlag,
                                        expression: "coupon.distributionFlag"
                                      }
                                    ],
                                    attrs: {
                                      horizontal: "",
                                      options: _vm.distributionPullDown,
                                      placeholder: "Please select",
                                      value: _vm.distributionId
                                    },
                                    on: {
                                      "update:value": function($event) {
                                        _vm.distributionId = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("CTextarea", {
                            attrs: {
                              label: "クーポン説明",
                              placeholder: "クーポン説明文...",
                              horizontal: "",
                              rows: "9"
                            },
                            model: {
                              value: _vm.coupon.description,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "description", $$v)
                              },
                              expression: "coupon.description"
                            }
                          }),
                          _c(
                            "div",
                            { staticStyle: { margin: "25px 0 20px 0" } },
                            [
                              _c("CInputFile", {
                                attrs: {
                                  label: "クーポン画像",
                                  horizontal: ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeImage($event)
                                  }
                                }
                              }),
                              _c("CImg", {
                                staticClass: "d-block col-sm-6 offset-sm-3",
                                attrs: {
                                  src: _vm.fireStorageImageUrl,
                                  rows: "mb-2"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "form-group form-row" },
                                [
                                  _c("CCol", {
                                    staticClass: "col-form-label",
                                    attrs: { tag: "label", sm: "3" }
                                  }),
                                  _c(
                                    "CCol",
                                    {
                                      staticClass: "form-inline",
                                      attrs: { sm: "9" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(this.getDisplayImageName()) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("CInput", {
                            attrs: {
                              label: "有効期限（開始日）",
                              type: "date",
                              horizontal: "",
                              lazy: true,
                              isValid: _vm.checkIfValid("start_date"),
                              invalidFeedback: "開始日を入力してください。"
                            },
                            model: {
                              value: _vm.coupon.start_date,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "start_date", $$v)
                              },
                              expression: "coupon.start_date"
                            }
                          }),
                          _c("CInput", {
                            attrs: {
                              label: "有効期限（終了日）",
                              type: "date",
                              horizontal: "",
                              lazy: true,
                              isValid: _vm.checkIfValid("end_date"),
                              invalidFeedback: "終了日を入力してください。"
                            },
                            model: {
                              value: _vm.coupon.end_date,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "end_date", $$v)
                              },
                              expression: "coupon.end_date"
                            }
                          }),
                          _c("CInput", {
                            attrs: {
                              label: "JANコード",
                              placeholder: "1234567890123",
                              horizontal: "",
                              lazy: true,
                              isValid: _vm.checkIfValid("jan_code"),
                              invalidFeedback:
                                "8桁または13桁の正しいコードを入力してください。"
                            },
                            model: {
                              value: _vm.coupon.jan_code,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "jan_code", $$v)
                              },
                              expression: "coupon.jan_code"
                            }
                          }),
                          _c("CInput", {
                            attrs: {
                              label: "割引金額",
                              placeholder: "0000",
                              horizontal: "",
                              lazy: true,
                              isValid: _vm.checkIfValid("discount"),
                              invalidFeedback:
                                "正しい割引額を入力してください。"
                            },
                            model: {
                              value: _vm.coupon.discount,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "discount", $$v)
                              },
                              expression: "coupon.discount"
                            }
                          }),
                          _c("CInput", {
                            attrs: {
                              label: "発行枚数（0は無制限）",
                              placeholder: "0000",
                              horizontal: "",
                              lazy: true,
                              isValid: _vm.checkIfValid("limitNum"),
                              invalidFeedback: "0以上の数字を入力してください。"
                            },
                            model: {
                              value: _vm.coupon.limitNum,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "limitNum", $$v)
                              },
                              expression: "coupon.limitNum"
                            }
                          }),
                          _c("CTextarea", {
                            attrs: {
                              label: "注意事項",
                              placeholder: "テキスト...",
                              horizontal: "",
                              rows: "9"
                            },
                            model: {
                              value: _vm.coupon.notice,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "notice", $$v)
                              },
                              expression: "coupon.notice"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "form-group form-row" },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { tag: "label", sm: "3" }
                                },
                                [
                                  _vm._v(" 再取得可能"),
                                  _c("br"),
                                  _vm._v(
                                    " 「ON」→「チェックを入れる」と、再取得可能。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " 「OFF」→「チェックを入れない」と、再取得不可となります。 "
                                  )
                                ]
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "form-inline",
                                  attrs: { sm: "9" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coupon.reacquisitionFlag,
                                        expression: "coupon.reacquisitionFlag"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: _vm.coupon.reacquisitionFlag,
                                      checked: Array.isArray(
                                        _vm.coupon.reacquisitionFlag
                                      )
                                        ? _vm._i(
                                            _vm.coupon.reacquisitionFlag,
                                            null
                                          ) > -1
                                        : _vm.coupon.reacquisitionFlag
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.coupon.reacquisitionFlag,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.coupon,
                                                "reacquisitionFlag",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.coupon,
                                                "reacquisitionFlag",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.coupon,
                                            "reacquisitionFlag",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group form-row" },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "col-form-label",
                                  attrs: { tag: "label", sm: "3" }
                                },
                                [
                                  _vm._v(" １精算につき、買上げ全商品対象"),
                                  _c("br"),
                                  _vm._v(
                                    " 「ON」→「チェックを入れる」と、1精算につき買い上げ全点数割引き。"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " 「OFF」→「チェックを入れない」と、1精算につき1点限り割引きとなります。 "
                                  )
                                ]
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "form-inline",
                                  attrs: { sm: "9" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.coupon.allPerCustomerFlag,
                                        expression: "coupon.allPerCustomerFlag"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: _vm.coupon.allPerCustomerFlag,
                                      checked: Array.isArray(
                                        _vm.coupon.allPerCustomerFlag
                                      )
                                        ? _vm._i(
                                            _vm.coupon.allPerCustomerFlag,
                                            null
                                          ) > -1
                                        : _vm.coupon.allPerCustomerFlag
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.coupon.allPerCustomerFlag,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.coupon,
                                                "allPerCustomerFlag",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.coupon,
                                                "allPerCustomerFlag",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.coupon,
                                            "allPerCustomerFlag",
                                            $$c
                                          )
                                        }
                                      }
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c("CInput", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            attrs: {
                              label: "優先番号",
                              placeholder: "",
                              horizontal: "",
                              lazy: true,
                              isValid: _vm.checkIfValid("priorityNumber"),
                              invalidFeedback: "1以上の数字を入力してください。"
                            },
                            model: {
                              value: _vm.coupon.priorityNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "priorityNumber", $$v)
                              },
                              expression: "coupon.priorityNumber"
                            }
                          }),
                          _c("CSelect", {
                            attrs: {
                              label: "公開ステータス",
                              horizontal: "",
                              options: _vm.options,
                              placeholder: "選択してください"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.onUpdateStatus($event)
                              }
                            },
                            model: {
                              value: _vm.coupon.status,
                              callback: function($$v) {
                                _vm.$set(_vm.coupon, "status", $$v)
                              },
                              expression: "coupon.status"
                            }
                          }),
                          this.distributions.length > 0
                            ? _c(
                                "div",
                                { staticClass: "form-group form-row" },
                                [_c("br"), _vm._v(" 店舗登録 ")]
                              )
                            : _vm._e(),
                          _vm._l(this.distributions, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "form-group form-row",
                                attrs: { item: item, index: index }
                              },
                              [
                                _c(
                                  "CCol",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isDistribution(),
                                        expression: "!isDistribution()"
                                      }
                                    ],
                                    staticClass: "form-inline col-form-label",
                                    attrs: { tag: "label", sm: "3" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.hasCoupon,
                                          expression: "item.hasCoupon"
                                        }
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: item.useId,
                                        label: item.name
                                      },
                                      domProps: {
                                        checked: item.hasCoupon,
                                        checked: Array.isArray(item.hasCoupon)
                                          ? _vm._i(item.hasCoupon, null) > -1
                                          : item.hasCoupon
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = item.hasCoupon,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "hasCoupon",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "hasCoupon",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "hasCoupon", $$c)
                                          }
                                        }
                                      }
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticStyle: {
                                          "padding-right": "10px",
                                          "padding-left": "5px"
                                        },
                                        attrs: { for: item.useId }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  ]
                                ),
                                _c(
                                  "CCol",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isDistribution(),
                                        expression: "isDistribution()"
                                      }
                                    ],
                                    staticClass: "form-inline col-form-label",
                                    attrs: { tag: "label", sm: "3" }
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                                _c(
                                  "CCol",
                                  {
                                    staticClass: "form-inline col-form-label",
                                    attrs: { tag: "label", sm: "1" }
                                  },
                                  [
                                    _c("CInputCheckbox", {
                                      attrs: {
                                        disabled: !item.hasCoupon,
                                        label: "すべて選択",
                                        checked: item.stores.every(function(v) {
                                          return v.hasCoupon
                                        })
                                      },
                                      on: {
                                        "update:checked": function($event) {
                                          return _vm.onClickedAllStoreCoupon(
                                            $event,
                                            item.id
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "CCol",
                                  {
                                    staticClass: "form-inline",
                                    attrs: { sm: "8" }
                                  },
                                  _vm._l(item.stores, function(item2, index2) {
                                    return _c(
                                      "div",
                                      {
                                        key: item2.id,
                                        staticClass: "form-group",
                                        staticStyle: { "padding-left": "15px" },
                                        attrs: { item2: item2, index2: index2 }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item2.hasCoupon,
                                              expression: "item2.hasCoupon"
                                            }
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            disabled: !item.hasCoupon,
                                            id: item2.id
                                          },
                                          domProps: {
                                            checked: item2.hasCoupon,
                                            checked: Array.isArray(
                                              item2.hasCoupon
                                            )
                                              ? _vm._i(item2.hasCoupon, null) >
                                                -1
                                              : item2.hasCoupon
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = item2.hasCoupon,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      item2,
                                                      "hasCoupon",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      item2,
                                                      "hasCoupon",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  item2,
                                                  "hasCoupon",
                                                  $$c
                                                )
                                              }
                                            }
                                          }
                                        }),
                                        _c(
                                          "label",
                                          {
                                            staticStyle: {
                                              "padding-right": "10px",
                                              "padding-left": "5px"
                                            },
                                            attrs: { for: item2.id }
                                          },
                                          [_vm._v(_vm._s(item2.name))]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ],
                              1
                            )
                          }),
                          _c(
                            "CRow",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    this.distributions.length > 0 &&
                                    _vm.isDistribution() &&
                                    !this.distributionEditFlag,
                                  expression:
                                    "this.distributions.length > 0 && isDistribution() && !this.distributionEditFlag"
                                }
                              ],
                              staticClass: "align-items-center"
                            },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "text-left",
                                  attrs: { col: "6" }
                                },
                                [
                                  _c(
                                    "CButton",
                                    {
                                      staticClass: "w-50",
                                      attrs: { color: "info" },
                                      on: { click: _vm.submitStore }
                                    },
                                    [_vm._v("店舗を登録する")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "CCardFooter",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.isDistribution() || this.distributionEditFlag,
                          expression:
                            "!isDistribution() || this.distributionEditFlag"
                        }
                      ]
                    },
                    [
                      _c(
                        "CRow",
                        { staticClass: "align-items-center" },
                        [
                          _c(
                            "CCol",
                            { staticClass: "text-left", attrs: { col: "6" } },
                            [
                              _c(
                                "CButton",
                                {
                                  staticClass: "w-50",
                                  attrs: { color: "info" },
                                  on: { click: _vm.submit }
                                },
                                [_vm._v("登録する")]
                              )
                            ],
                            1
                          ),
                          _vm.isAdmin()
                            ? _c(
                                "CCol",
                                {
                                  staticClass: "text-right",
                                  attrs: { col: "6" }
                                },
                                [
                                  !this.isNew
                                    ? _c(
                                        "CButton",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { color: "danger" },
                                          on: {
                                            click: function($event) {
                                              _vm.dangerModal = true
                                            }
                                          }
                                        },
                                        [_vm._v("削除する")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CModal",
        {
          attrs: {
            title: "クーポン削除",
            color: "danger",
            show: _vm.dangerModal
          },
          on: {
            "update:show": function($event) {
              _vm.dangerModal = $event
            },
            ok: function($event) {
              return _vm.onClickedDelete()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function($event) {
                          _vm.dangerModal = false
                        }
                      }
                    },
                    [_vm._v("キャンセル")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { color: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.onClickedDelete()
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [_vm._v(" " + _vm._s(_vm.coupon.title) + "を本当に削除しますか？ ")]
      ),
      _c(
        "CModal",
        {
          attrs: {
            title: "クーポン削除",
            color: "danger",
            show: _vm.warnModal
          },
          on: {
            "update:show": function($event) {
              _vm.warnModal = $event
            },
            ok: function($event) {
              return _vm.onClickedDelete()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function($event) {
                          _vm.warnModal = false
                        }
                      }
                    },
                    [_vm._v("キャンセル")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { color: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.onClickedDelete()
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.coupon.title) +
              "は、実績登録されており、削除すると実績との紐付けがなくなります。"
          ),
          _c("br"),
          _vm._v(" 本当に削除しますか？ ")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }